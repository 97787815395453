const handleUrl = (path, isSubdomain = true) => {
  let baseUrl = document.location.origin;

  if (isSubdomain) {
    if (baseUrl.hostname === 'lvh.me') baseUrl = baseUrl.replace('lvh.me:3000', 'app.lvh.me:3000');
    else if (baseUrl.startsWith('www.')) baseUrl = baseUrl.replace('www.', 'app.');
    else baseUrl = baseUrl.replace('//', '//app.');
  }

  return `${baseUrl}/${path}`;
};

export default handleUrl;
